import {IParentMenuOption} from "shared-components/booking-options-section/ChildMenuOptionOverlay/types";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {ClientService} from "app/services/client/client.service";
import {first, map} from "rxjs/operators";
import {
  ICustomer,
  IGroupWidgetSchedule,
  ILayoutMinimalABC,
  ITableItemABC,
  ITablePickerData,
  IUpdateActiveChildMenuOptionPayload
} from "app/models";
import {
  bookingErrorMessageType,
  bookingErrorType,
  BookingMethods,
  IBookingErrorMinimal,
  IBookingMenuOption,
  IBookingMenuOptionExtrasUpdater,
  IBookingResponseData,
  ILayoutMinimal,
  IOwnedVenue,
  IPaymentType,
  IServicePaymentOption,
  ITableItem,
  ITableSectionList,
  JOIN_PREFIX,
  SECTION_ANY_ID,
  servicePaymentType
} from 'shared-types/index';
import {RootState} from "app/main/rootReducer";
import {
  setBookingOptions,
  toggleBookingOption,
  updateActiveChildMenuOptions,
  updateCachedMenuOptionDetails
} from "app/reducers/bookingOptionSlice";
import {IBookingOutgoing, IPaymentOverride, IStandbyRequest, PaymentMethod} from "app/services/booking/booking.types";
import {ROUTE_NAMES} from "app/models/route.types";
import {addPaymentRoutes, checkForLoadedSchedule, goToRoute, routeSlice} from "app/reducers/routeSlice";
import {footerNavTypes} from "shared-components/footer-nav/types";
import PhoneNumberService from "shared-services/phone-number-service/index";
import {libPhoneLoaded} from "app/reducers/libPhoneSlice";
import {IResponse, loadStatus} from "app/models/common.types";
import {BookingService} from "app/services/booking/booking.service";
import {UtilsService} from "app/services/utils.service";
import {Observable, Subscription} from "rxjs";
import {setBookingSaveLoader, setOutgoingBooking, setSavedBooking} from "app/reducers/bookingSlice";
import {history} from '../../../_helpers/history'

import {setTablePickerData} from "app/reducers/venueGridSlice";
import {BookingAvailabilityService} from "shared-services/booking-availability-service";
import SectionsService from "shared-services/sections-service";
import TableSelectionService from "shared-services/table-selection-service";
import DateUtilsService from "shared-services/date-utils-service";
import {MenuOptionsService} from "shared-services/menu-options-service";
import {
  checkHasPromoCode,
  paymentSlice,
  setIsPaymentOverride,
  setOriginalPayment,
  setPayment
} from "app/reducers/paymentSlice";
import {cloneDeep} from "lodash-es";
import {preselectTableId} from "app/reducers/tableSlice";
import {AppDispatch} from "app/main/store";

const NS = 'StateHelperService';

export namespace StateHelperService {
    let getPaymentTypeSubs: Subscription;

    export const getBOForChildMenuOption = createAsyncThunk(
        "bookingOption/updateActiveChildMenuOption",
        (parentMenuOption: IParentMenuOption, {dispatch, getState}) => {
            // console.log('dispatch', dispatch);
            // console.log('state', getState());

            const selectedSchedule = (getState() as RootState).venueGridReducer.selectedSchedule;

            const implicitIds = parentMenuOption && parentMenuOption.childMenuOptionIds ? parentMenuOption.childMenuOptionIds : [];
            const explicitIds = parentMenuOption && parentMenuOption.explicitChildMenuOptionIds ? parentMenuOption.explicitChildMenuOptionIds : [];

            if (!implicitIds.length && !explicitIds.length) {

                const payload: IUpdateActiveChildMenuOptionPayload = null;
                dispatch(updateActiveChildMenuOptions(payload))
                // dispatch({type: Type.UPDATE_ACTIVE_CHILD_MENU_OPTION, payload: null} as IActionGen<IUpdateActiveChildMenuOptionPayload>);
                // resolve();
            }

            const ids: string[] = implicitIds.concat(explicitIds);

            ClientService.getBookingOptions(ids.join(','), (selectedSchedule.venueDetails as IOwnedVenue).id)
                .pipe(
                    first()
                ).subscribe((response: IServicePaymentOption[]) => {
                if (response) {
                    const payload = {
                        implicitChildMenuOptions: response.splice(0, implicitIds.length), // extracts the implicit list
                        explicitChildMenuOptions: response || [], // explicit is whatever is left

                    } as IUpdateActiveChildMenuOptionPayload
                    dispatch(updateActiveChildMenuOptions(payload))
                }
            }, err => {
                // @todo dispatch empty update and some kind of error UI
            });
        }
    );

    export const updatePaymentBasedOnBookingOptions = createAsyncThunk(
        "bookingOption/updateCachedMenuOptionDetails",
        (args: IBookingMenuOption[], {dispatch, getState}) => {

            const activeVenue = (getState() as RootState).appInitReducer.activeVenue;
            const selectedService = (getState() as RootState).groupWidgetReducer.selectedService;
            const bookingTime = (getState() as RootState).venueGridReducer.bookingTime;
            const covers = (getState() as RootState).paxCounterReducer.count;
            const bookingOptionsValid = (getState() as RootState).bookingOptionReducer.bookingOptionsValid;
            const paymentMethod = (getState() as RootState).paymentReducer.paymentMethod;

            const opts = args.filter(opt => opt.quantity > 0);
            const optsFlat = MenuOptionsService.getFlatExtras(opts);

            if (getPaymentTypeSubs) { // stops spinner spamming issues
              getPaymentTypeSubs.unsubscribe();
              dispatch(setBookingSaveLoader(false));
            }

            if (optsFlat.length) {
              dispatch(setBookingSaveLoader(true));
              getPaymentTypeSubs = ClientService.getPaymentType((activeVenue as IOwnedVenue).id, optsFlat, selectedService.id, bookingTime.time, covers, true)
                .pipe(
                  first(),
                  map(({data}: IResponse<IPaymentType>) => data)
                ).subscribe((data) => {

                  dispatch(setBookingSaveLoader(false));
                  const paymentDetails: IPaymentOverride = {
                    paymentType: data.paymentTypeName,
                    price: data.amount
                  }
                  dispatch(setPayment(paymentDetails));
                  dispatch(setOriginalPayment(paymentDetails));

                  // Adding payment routes for Direct payment selection. Was creating an issue where if Direct is already selected and then BO toggled off and on again - it was redirecting to payment page
                  bookingOptionsValid && paymentMethod === PaymentMethod.Direct ?
                    dispatch(addPaymentRoutes(paymentDetails.paymentType !== servicePaymentType.noPayment)) :
                    dispatch(addPaymentRoutes(false));
                });
            } else {
              const paymentDetails: IPaymentOverride = {
                paymentType: servicePaymentType.noPayment,
                price: 0
              }
              dispatch(setPayment(paymentDetails));
              dispatch(setOriginalPayment(paymentDetails));
              dispatch(addPaymentRoutes(false));
            }

        }
    )

    export const getLatestPrice = createAsyncThunk(
        "bookingOption/updateCachedMenuOptionDetails",
        (args: void, {dispatch, getState}) => {

            const activeVenue = (getState() as RootState).appInitReducer.activeVenue;
            const selectedService = (getState() as RootState).groupWidgetReducer.selectedService;
            const bookingTime = (getState() as RootState).venueGridReducer.bookingTime;
            const covers = (getState() as RootState).paxCounterReducer.count;

            dispatch(setBookingSaveLoader(true));
            ClientService.getPaymentType((activeVenue as IOwnedVenue).id, [], selectedService.id, bookingTime.time, covers)
                .pipe(
                    first(),
                    map(({data}: IResponse<IPaymentType>) => {
                        return data;
                    })
                ).subscribe((data) => {
                dispatch(setBookingSaveLoader(false));
                const paymentDetails: IPaymentOverride = {
                    paymentType: data.paymentTypeName,
                    price: data.amount
                }
                dispatch(setPayment(paymentDetails));
                dispatch(setOriginalPayment(paymentDetails)); // Setting Original Payment that's sent back from API - We need it if the payment type explicitly changed by user nad then changed back to original payment type
            });

        }
    )

    export const updateBOExtras = createAsyncThunk(
        "bookingOption/updateBOExtras",
        (payload: IBookingMenuOptionExtrasUpdater, {dispatch, getState}) => {
            const {parentId, implicitChildMenuOptions, explicitChildMenuOptions, isSameForAll} = payload;

            const selectedBookingOptions = (getState() as RootState).bookingOptionReducer.selectedBookingOptions;

            const selectedMenuOptions = [
                ...selectedBookingOptions
            ];
            selectedMenuOptions.forEach((bookingOption, index) => {
                if (bookingOption.menuOptionId === parentId) {
                    const hasChildItems = implicitChildMenuOptions && implicitChildMenuOptions.length || explicitChildMenuOptions && explicitChildMenuOptions.length;
                    if (hasChildItems) {

                        const _isSameForAll = isSameForAll || false;

                        const extrasVal = {
                            implicitChildMenuOptions: implicitChildMenuOptions || [],
                            explicitChildMenuOptions: MenuOptionsService.populateExplicitMenuOptions(explicitChildMenuOptions, _isSameForAll, bookingOption.quantity),
                            isSameForAll: _isSameForAll
                        };
                        selectedMenuOptions[index] = {...bookingOption, extras: extrasVal};
                    } else {
                        delete bookingOption.extras;
                    }
                }
            });
            dispatch(setBookingOptions({bookingOptions: selectedMenuOptions, singleMenuPerBooking: false}));
        });

    export const updateCachedBODetails = createAsyncThunk(
        "bookingOption/updateCachedMenuOptionDetails",
        (ids: string[], {dispatch, getState}) => {
            return new Promise((resolve, reject) => {
                const selectedSchedule = (getState() as RootState).venueGridReducer.selectedSchedule;
                const venue = selectedSchedule.venueDetails;
                ClientService.getBookingOptions(ids.join(','), (venue as IOwnedVenue).id)
                    .pipe(first())
                    .subscribe((response: IServicePaymentOption[]) => {
                        if (response) {
                          dispatch(updateCachedMenuOptionDetails(response));
                        }
                        resolve(null)
                    }, err => {
                        reject();
                    });
            });

        })

    export const updateRoute = createAsyncThunk(
        "route/updateRoute",
        (type: string, {dispatch, getState}) => {
            const {routeReducer, venueGridReducer, groupWidgetReducer, paxCounterReducer} = getState() as RootState;
            const {redirectTo, routeList} = routeReducer;
            const currentStepIndex = routeList.findIndex(route => route === redirectTo);

            let routeTo = ROUTE_NAMES.DEFAULT;

            switch (type) {
                case footerNavTypes.next: {
                    if (currentStepIndex < routeList.length) {
                        routeTo = routeList[currentStepIndex + 1];
                    }
                    break;
                }
                case footerNavTypes.manageBooking: {
                    const currentStepIndex = routeList.findIndex(route => route === ROUTE_NAMES.MANAGE_BOOKING);
                    if (currentStepIndex < routeList.length) {
                        routeTo = routeList[currentStepIndex + 1];
                    }
                    break;
                }

                case footerNavTypes.book: {
                    if (currentStepIndex < routeList.length) {
                        const nextRoute = routeList[currentStepIndex + 1];

                        // don't want it to trigger on thankyou
                        if (nextRoute === ROUTE_NAMES.BOOKING) {
                            const venueId = venueGridReducer.selectedSchedule.venueId;
                            const serviceId = groupWidgetReducer.selectedService.id;
                            const bookingTime = venueGridReducer.bookingTime.time;
                            const covers = paxCounterReducer.count;

                            // Resetting BO to default state
                            resetToDefaultState(dispatch)

                            dispatch(preselectTableId(''));

                            ClientService.getTablePicker(venueId, serviceId, bookingTime, covers)
                                .subscribe(data => {
                                    const time = DateUtilsService.dateStrToDateTime(bookingTime);
                                    const {
                                      tableSelectionData,
                                      sectionsLookup,
                                      autoSelectTableId,
                                      isEvent
                                    } = getTableSelectionData(data, covers, venueGridReducer.selectedSchedule, time, venueGridReducer.isWalkIn);
                                    dispatch(setTablePickerData({data: tableSelectionData, sectionsLookup, isEvent}));
                                    dispatch(preselectTableId(autoSelectTableId));
                                }, error => {
                                    console.warn('getTablePicker', error);
                                });
                        }

                        routeTo = nextRoute;
                        // dispatch(goToRoute(routeTo));
                    }
                    break;
                }
                case footerNavTypes.prev: {
                    let prevIndex = currentStepIndex - 1;
                    prevIndex = prevIndex < 0 ? 0 : prevIndex;
                    if (routeList[prevIndex] === routeList[0]) {
                        dispatch(checkForLoadedSchedule(true))
                    }
                    routeTo = routeList[prevIndex];
                    // dispatch(goToRoute(routeTo));
                    break;
                }
                case footerNavTypes.standby: {
                    routeTo = ROUTE_NAMES.THANK_YOU;
                    break;
                }
            }
            history.push(routeTo);
            dispatch(goToRoute(routeTo));


        });

    export const saveStandbyBooking = createAsyncThunk(
        "route/saveStandbyBooking",
        async (type: footerNavTypes, {dispatch, getState}) => {
            const {customerReducer, appInitReducer, groupWidgetReducer, venueGridReducer, paxCounterReducer} = getState() as RootState;

            const customerInfo: ICustomer = {...customerReducer.customerDetails, notes: customerReducer.bookingNotes};

            // Populate stand-by payload
            const bookingRequest: IStandbyRequest = {
                sectionId: "any",
                customer: customerInfo,
                tags: customerReducer.tags,
                covers: paxCounterReducer.count,
                serviceId: groupWidgetReducer.selectedService.id,
                desiredBookingTime: venueGridReducer.bookingTime.time
            }

            try {
                await ClientService.saveStandbyBooking(bookingRequest, appInitReducer.activeVenue.id).toPromise();
                dispatch(updateRoute(type));
            } catch (error) {
                // Display error message on <ErrorPage />, when standby booking creation fails.
                const errorMessageDetails: IBookingErrorMinimal = {
                    name: bookingErrorType.badRequest,
                    buttonText: "Make another booking",
                    heading: "Error with Standby list",
                    messageType: bookingErrorMessageType.serverError,
                    message: "Standby list is exhausted for this day, please try another day."
                }
                dispatch(paymentSlice.actions.setBookingError(errorMessageDetails));
                dispatch(paymentSlice.actions.setTryAgainBtn(true));
                dispatch(routeSlice.actions.addBookingErrorRoute());
                dispatch(updateRoute(footerNavTypes.next));
            }
        }
    )

    export const saveBooking = createAsyncThunk(
        "route/saveBooking",
        (type: footerNavTypes, {dispatch, getState}) => {
            const {customerDetails, tags, bookingNotes} = (getState() as RootState).customerReducer;
            const bookingTime = (getState() as RootState).venueGridReducer.bookingTime;
            const selectedService = (getState() as RootState).groupWidgetReducer.selectedService
            const covers = (getState() as RootState).paxCounterReducer.count;
            let selectedMenuOptions = (getState() as RootState).bookingOptionReducer.selectedBookingOptions;
            const selectedSchedule = (getState() as RootState).venueGridReducer.selectedSchedule;
            const {selectedTableIds, tableList} = (getState() as RootState).tableReducer;
            const payment = (getState() as RootState).paymentReducer.paymentOverride;
            let emailConfirmation = (getState() as RootState).paymentReducer.emailConfirmation;
            let smsConfirmation = (getState() as RootState).paymentReducer.smsConfirmation;
            const paymentMethod = (getState() as RootState).paymentReducer.paymentMethod;
            const isPaymentOverride = (getState() as RootState).paymentReducer.isPaymentOverride;

            if (payment.paymentType !== servicePaymentType.noPayment) {
                emailConfirmation = false;
                smsConfirmation = false;
            }

            const paymentOverride: IPaymentOverride = {
                paymentType: payment.paymentType,
                price: payment.price
            }

            const hasPayment: boolean = payment.paymentType !== servicePaymentType.noPayment
                || (paymentOverride && paymentOverride.paymentType !== servicePaymentType.noPayment);

            // make a copy before mutating it
            selectedMenuOptions = cloneDeep(selectedMenuOptions);

            // makes sure parent option quantity is set correctly when singleMenuPerBooking
            selectedMenuOptions = BookingService.getBookingOptions(
                selectedService.paymentDetails.options,
                selectedMenuOptions,
                covers,
                selectedService.paymentDetails.singleMenuPerBooking
            );

            // prepares data structure for back end consumption
            const selectedMenuOptionsFlat = MenuOptionsService.getFlatExtras(selectedMenuOptions);

            // extract list of table ids from table join
            let tableIds: string[] = selectedTableIds || [];
            if (tableIds?.length && tableIds[0].indexOf(JOIN_PREFIX) === 0) {
              const selectedTableJoin = tableList.find(t => t._id === tableIds[0]);
              if (selectedTableJoin) {
                tableIds = selectedTableJoin.tables;
              }
            }

            let bookingOutgoing: IBookingOutgoing = {
                customer: UtilsService.omitFalseyProps(customerDetails),
                time: bookingTime.time,
                notes: bookingNotes,
                selectedMenuOptions: selectedMenuOptionsFlat,
                numOfPeople: covers,
                sectionId: 'all',
                service: {
                    id: selectedService.id,
                    duration: selectedService.duration || 30,
                    serviceType: selectedService.serviceType,
                    name: selectedService.name
                },
                tags,
                paymentOverride: isPaymentOverride ? paymentOverride : null,
                sendSmsNotifications: smsConfirmation,
                sendEmailNotifications: emailConfirmation,
                seatingSpecification: {
                    tableIds,
                    allowBookingWhenNominatedTablesNotAvailable: true
                },
                paymentFlow: payment.paymentType !== servicePaymentType.noPayment ? paymentMethod : null
            };

            bookingOutgoing = UtilsService.omitFalseyProps(bookingOutgoing);

            dispatch(setOutgoingBooking(bookingOutgoing));

            const saveOrUpdate$: Observable<IResponse<IBookingResponseData>> = BookingService.saveBooking(bookingOutgoing, selectedSchedule.venueId);
            dispatch(setBookingSaveLoader(true))
            saveOrUpdate$
                .pipe(
                    first(),
                ).subscribe((response) => {
                console.log('response.data', response.data)
                dispatch(setBookingSaveLoader(false))
                dispatch(setSavedBooking(response.data))

                if (hasPayment) {
                    dispatch(checkHasPromoCode());
                }
                // Following NBI-5988
                if (hasPayment && paymentMethod === PaymentMethod.Direct) {
                    dispatch(addPaymentRoutes(true))
                }

                dispatch(updateRoute(type))
            });
        });

    export const loadLibPhoneNumber = createAsyncThunk(
        "appInit/loadLibPhoneNumber",
        (arg: void, {dispatch, getState}) => {
            return new Promise((resolve, reject) => {
                const libPhoneNumberLoaded = (getState() as RootState).libPhoneReducer.libPhoneNumberLoaded;
                const libPhoneNumberLoading = (getState() as RootState).libPhoneReducer.libPhoneNumberLoading;
                if (libPhoneNumberLoaded || libPhoneNumberLoading) {
                    return;
                }

                dispatch(libPhoneLoaded(loadStatus.loading));
                PhoneNumberService.loadLibPhoneNumber()
                    .subscribe((lib) => {
                        dispatch(libPhoneLoaded(loadStatus.success));
                        resolve(null);
                    }, (err: string) => {
                        dispatch(libPhoneLoaded(loadStatus.failed));
                        reject();
                    });
            })

        })

    export const countdownTimerExpired = createAsyncThunk(
        "appInit/countdownTimerExpired",
        (arg: void, {dispatch, getState}) => {

            const {} = getState() as RootState;

            const errorMessageDetails: IBookingErrorMinimal = {
                name: bookingErrorType.bookingExpired,
                heading: 'Booking Expired',
                messageType: bookingErrorMessageType.paymentTimeout,
                message: 'Sorry, your booking timed out.',
                buttonText: ''
            }
            dispatch(paymentSlice.actions.setBookingError(errorMessageDetails))

            dispatch(paymentSlice.actions.setTryAgainBtn(true));
            dispatch(routeSlice.actions.addBookingErrorRoute());
            dispatch(updateRoute(footerNavTypes.next));

        })
}

function getTableSelectionData(data: ITablePickerData, covers: number, selectedSchedule: IGroupWidgetSchedule, bookingTimeAsDate: Date, isWalkIn: boolean): {
  tableSelectionData: ITableSectionList,
  sectionsLookup: any,
  autoSelectTableId: string,
  isEvent: boolean
} {

    const {times} = selectedSchedule.services.find(s => s.id && s.id === data.service.id);
    const sectionsLookup = SectionsService.getSectionsLookUp(selectedSchedule, data.service.layouts);

    const tables: ITableItemABC[] = data.service.layouts.reduce((acc, lo) => {
        if (lo.tables) {
            acc = acc.concat(lo.tables.reverse());
        }
        return acc;
    }, []);

    // just used a subset of properties, since the full interfaces differ between diary and group widget
    const bookingsByTable = BookingAvailabilityService.getBookingsByTable(
        data.bookings.map(({tables, status, duration, time, serviceId, _id, people}) => {
            return {_id, serviceId, people, tables, status, duration, time: DateUtilsService.dateStrToDateTime(time)};
        })
    );

    data.service.times = times;

    const layouts: ILayoutMinimalABC[] = cloneDeep(data.service.layouts);
    layouts.forEach(l => {
        l.tablesJoins.forEach(tj => {
            (tj as unknown as ITableItem).tables = (tj.tables as unknown as ITableItemABC[]).map(t => t._id) as string[];
        })
    });

    // ABC sets `tables` as objects, whereas diary sets them as ids
    const layoutsWithTablesAsIds = layouts as unknown as ILayoutMinimal[];

    const selectedTime = BookingAvailabilityService.checkBookingsAtTime(
        bookingTimeAsDate, bookingTimeAsDate, selectedSchedule, data.service, layoutsWithTablesAsIds, data.service.duration,
        covers, tables, bookingsByTable, SECTION_ANY_ID, null, true,
        isWalkIn ? BookingMethods.walkin : BookingMethods.phone);

    const tableSelectionData = TableSelectionService.createTableSectionList(selectedTime, tables, sectionsLookup);
    const selectedTable = TableSelectionService.getTableByTime(selectedTime);
    const autoSelectTableId = selectedTable?._id || '';

    const isEvent = data.service.id.indexOf('event_') === 0;

    return {tableSelectionData, sectionsLookup, autoSelectTableId, isEvent};
}

function resetToDefaultState(dispatch: AppDispatch){
    dispatch(toggleBookingOption(true)); // Resetting the Show BO toggle. So that it always shows - default state.
    dispatch(setIsPaymentOverride(false)); // Resetting the Payment Override to false - default state
    dispatch(setBookingOptions({bookingOptions: [], singleMenuPerBooking: false}));
}
